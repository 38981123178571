.#{$wi-css-prefix}-wu-chanceflurries:before { content: $snow-wind; }
.#{$wi-css-prefix}-wu-chancerain:before     { content: $rain; }
.#{$wi-css-prefix}-wu-chancesleat:before    { content: $sleet; }
.#{$wi-css-prefix}-wu-chancesnow:before     { content: $snow; }
.#{$wi-css-prefix}-wu-chancetstorms:before  { content: $thunderstorm; }
.#{$wi-css-prefix}-wu-clear:before          { content: $day-sunny; }
.#{$wi-css-prefix}-wu-cloudy:before         { content: $day-cloudy; }
.#{$wi-css-prefix}-wu-flurries:before       { content: $snow-wind; }
.#{$wi-css-prefix}-wu-hazy:before           { content: $day-haze; }
.#{$wi-css-prefix}-wu-mostlycloudy:before   { content: $day-cloudy; }
.#{$wi-css-prefix}-wu-mostlysunny:before    { content: $day-sunny; }
.#{$wi-css-prefix}-wu-partlycloudy:before   { content: $day-cloudy; }
.#{$wi-css-prefix}-wu-partlysunny:before    { content: $day-sunny; }
.#{$wi-css-prefix}-wu-rain:before           { content: $showers; }
.#{$wi-css-prefix}-wu-sleat:before          { content: $sleet; }
.#{$wi-css-prefix}-wu-snow:before           { content: $snow; }
.#{$wi-css-prefix}-wu-sunny:before          { content: $day-sunny; }
.#{$wi-css-prefix}-wu-tstorms:before        { content: $thunderstorm; }
.#{$wi-css-prefix}-wu-unknown:before        { content: $day-sunny; }