@import './colors'
@import './variables'

$theme-colors: ("danger": $red, "primary": $purple, "info": $teal, "secondary": $darkBlue, "success": $success, "light": white, "warning": $lightOrange, "smoke": whitesmoke, "medium": $mediumGrey)

$body-bg: whitesmoke

$font-family-sans-serif: 'Roboto', sans-serif
$font-family-serif: 'Roboto Slab', serif

span
  font-family: $font-family-sans-serif

h1
  font-size: $font-size-1
  font-weight: bold
  text-align: center
  @media screen and (max-width: $small)
    font-size: $font-size-2
h2
  font-size: $font-size-2
  font-weight: bold
  @media screen and (max-width: $small)
    font-size: $font-size-3

h3
  font-size: $font-size-3
  font-weight: bold
  @media screen and (max-width: $small)
    font-size: $font-size-4
label
  font-size: $font-size-3
  @media screen and (max-width: $small)
    font-size: $font-size-4

h4
  font-size: $font-size-4 !important
  font-weight: bold !important
  @media screen and (max-width: $small)
    font-size: $font-size-5 !important
h5
  font-size: $font-size-5

p
  font-size: $font-size-4

a
  @media screen and (max-width: $small)
    font-size: $font-size-4

.full-size
  font-size: $font-size-3

span
  font-size: $font-size-4

.btn
  font-size: $font-size-3
  font-weight: bold
  @media screen and (max-width: $small)
    font-size: $font-size-4

.btn-sm
  font-size: $font-size-4
  font-weight: normal
