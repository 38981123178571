@import './colors'

.thead-blue
	background-color: $blue
	color: $white
	text-transform: uppercase
	th
		font-weight: normal


