/* You can add global styles to this file, and also import other style files */

// http://ionicframework.com/docs/theming/
@import "~@ionic/angular/css/core.css"
@import "~@ionic/angular/css/normalize.css"
@import "~@ionic/angular/css/structure.css"
@import "~@ionic/angular/css/typography.css"

@import "~@ionic/angular/css/padding.css"
@import "~@ionic/angular/css/float-elements.css"
@import "~@ionic/angular/css/text-alignment.css"
@import "~@ionic/angular/css/text-transformation.css"
@import "~@ionic/angular/css/flex-utils.css"

// Angular theming
@import './assets/scss/sd-theme/tables'
@import './assets/scss/sd-theme/colors'
@import "~bootstrap/scss/functions"
@import 'variables'
@import './assets/scss/sd-theme/theme'
@import "~bootstrap/scss/bootstrap"
@import "~@angular/material/prebuilt-themes/indigo-pink.css"
@import '~@swimlane/ngx-datatable/index.css'
@import '~@swimlane/ngx-datatable/themes/material.css'
@import '~@swimlane/ngx-datatable/themes/bootstrap.css'
@import '~@swimlane/ngx-datatable/assets/icons.css'
@import "~ngx-toastr/toastr.css"
@import '~viewerjs/dist/viewer.css'
@import "~angular2-multiselect-dropdown/themes/default.theme.css"


@font-face
  font-family: 'Roboto'
  font-style: normal
  font-weight: 400
  font-display: 'swap'
  src: local('Roboto'), local('Roboto-Regular'), url('/assets/fonts/Roboto-extended/roboto-v18-extended-regular.woff2') format('woff2'), url('/assets/fonts/Roboto-extended/roboto-v18-extended-regular.woff') format('woff'), url('/assets/fonts/Roboto-extended/roboto-v18-extended-regular.ttf') format('truetype')
@font-face
  font-family: 'Roboto'
  font-style: italic
  font-weight: 400
  font-display: 'swap'
  src: local('Roboto Italic'), local('Roboto-Italic'), url('/assets/fonts/Roboto-extended/roboto-v18-extended-italic.woff2') format('woff2'), url('/assets/fonts/Roboto-extended/roboto-v18-extended-italic.woff') format('woff'), url('/assets/fonts/Roboto-extended/roboto-v18-extended-italic.ttf') format('truetype')
@font-face
  font-family: 'Roboto'
  font-style: normal
  font-weight: 700
  font-display: 'swap'
  src: local('Roboto Bold'), local('Roboto-Bold'), url('/assets/fonts/Roboto-extended/roboto-v18-extended-700.woff2') format('woff2'), url('/assets/fonts/Roboto-extended/roboto-v18-extended-700.woff') format('woff'), url('/assets/fonts/Roboto-extended/roboto-v18-extended-700.ttf') format('truetype')
@font-face
  font-family: 'Roboto'
  font-style: italic
  font-weight: 700
  font-display: 'swap'
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url('/assets/fonts/Roboto-extended/roboto-v18-extended-700italic.woff2') format('woff2'), url('/assets/fonts/Roboto-extended/roboto-v18-extended-700italic.woff') format('woff'), url('/assets/fonts/Roboto-extended/roboto-v18-extended-700italic.ttf') format('truetype')
@font-face
  font-family: 'Karla'
  font-style: normal
  font-weight: 700
  font-display: 'swap'
  src: local('Karla Bold'), local('Karla-Bold'), url('/assets/fonts/Karla/karla-700.ttf') format('truetype')

*
  font-family: $font-family-sans-serif
  --ion-font-family: #{$font-family-sans-serif}
  -webkit-font-smoothing: subpixel-antialiased
  text-rendering: optimizeLegibility

.fa
  font-family: 'FontAwesome' !important

.fs-1
  font-size: 1em !important
.fs-2
  font-size: 1.2em !important
.fs-3
  font-size: 1.3em !important
.fs-4
  font-size: 1.4em !important
.fs-5
  font-size: 1.5em !important

.web-customtextarea
  .native-textarea
    max-height: 228px !important
    padding: 1.1em 1.5em 0 1.5em !important
  .textarea-wrapper
    height: fit-content !important

.cursor-on
  cursor: pointer!important

.cursor-loading
  cursor: progress!important

.opacity-reduce
  opacity: 0.5!important

.grey-out
  pointer-events: none!important
  opacity: 0.6!important

.emphasis
  color: $blue!important
  font-size: 1em!important
  font-weight: bold

.discrete
  opacity: 0.5

.link:hover
  cursor: pointer

.no-outline
  outline: none

// color schema for app
.diary-color
  color: $purple !important

.diary-bg-color
  background-color: $purple !important

.diary-border-color
  border-color: $purple !important

.tasks-color
  color: $lightGreen !important

.tasks-bg-color
  background-color: $lightGreen !important

.tasks-border-color
  border-color: $lightGreen !important

.administartion-color
  color: $brightOrange !important

.administartion-bg-color
  background-color: $brightOrange !important

.administartion-border-color
  border-color: $brightOrange !important

// Tabs Config
.inner-tabs a
  padding: 10px 10px
  text-decoration: none
  margin-top: 10px
  margin-left: 5px
  margin-bottom: 20px
  display: inline-block
  background-color: $grey
  border-radius: 4px

.inner-tabs a:visited, .inner-tabs a:link
  color: $white

.inner-tabs a:hover
  background-color: $teal

.btn-outline-warning:hover:enabled
  color: white!important

.split-pane-main
  overflow: auto!important

app-home
  overflow: auto!important

.inner-tabs a.active
  background-color: $teal

//Toaster customization
.toast-warning
  opacity: 1
  background-color: $warning!important

.toast-error
  opacity: 1
  background-color: $error!important

.toast-info
  opacity: 1
  background-color: $info!important

.toast-success
  opacity: 1
  background-color: $forestGreen!important

// Buttons config
.btn:focus,.btn:active
   outline: none !important
   box-shadow: none

.btn-circle
  width: 70px
  height: 70px
  padding: 22px 22px!important
  border-radius: 35px!important
  font-size: 24px!important
  line-height: 1.33!important

button
  &.secondary-action-button
    background: none
    color: $purple
    border-radius: 2px
    border: 1px solid $purple

  &.primary-action-button
    background-color: $purple
    color: white
    border-radius: 2px
    border: 1px solid $purple

ion-button
  &.primary-action-button
    font-size: 90%
    text-transform: none
    --box-shadow: none
    span
      min-width: 4em

  &.secondary-action-button
    font-size: 90%
    text-transform: none
    color: var(--ion-color-base)
    --background: var(--ion-color-contrast)
    --box-shadow: none
    span
      min-width: 4em

  &.option-button
    font-size: 90%
    text-transform: none
    color: var(--ion-color-base)
    --background: var(--ion-color-contrast)
    --box-shadow: none
    --border-width: 1px
    --border-style: solid
    --border-color: var(--ion-color-base)
    --padding-start: 0.7em
    --padding-end: 0.7em
    span
      min-width: 4em
      padding: 0 0.5em 0 0.5em
    &.selected-option-button
      --background: var(--ion-color-base)
      color: var(--ion-color-contrast)
    &:not(.selected-option-button):hover
      --background: rgba(var(--ion-color-base-rgb), 0.1)

  &.non-interactive-button
      cursor: default
      pointer-events: none

.primary-action-button:hover, .secondary-action-button:hover, .option-button:hover, .btn:hover, .top-button:hover
  opacity: 0.9

.progress-row
  margin-top: 0.8em
  max-height: 32px

.right-align
  float: right

.full-height
  height: 100%

.btn-row-fixed
  position: fixed
  bottom: 40px
  z-index: $z-index-button
  right: 12em

.btn-list
  padding-left: 0
  display: flex
  align-items: center

.btn-list li
  display: inline-block
  margin-right: 10px
  button, a
    font-weight: 500
    display: flex
    align-items: center

.btn-fixed-space
  margin-bottom: 40px

// Radio Buttons
.custom-control-label:hover
  cursor: pointer !important

// Form config

.form-input-icon
  align-self: flex-start
  min-width: 1.8em!important
  margin-left: 1em

.required-field
  color: red

input[type='file']:hover
  cursor: pointer

// Material customization
.mat-tooltip
  font-size: $font-size-5
  max-width: 20vh!important

.mat-tooltip-wide-and-multiline
  white-space: pre-wrap
  min-width: 250px

.mat-radio-label-content
  padding-left: 2em!important
  font-size: 16px!important

.image-download-button
  position: absolute
  width: 20px
  right: 70px
  color: white
  cursor: pointer
  font-size: 20px
  text-align: center
  display: flex
  justify-content: center
  align-items: center

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle
  border-color: $purple!important

.mat-radio-button.mat-accent .mat-radio-inner-circle
  color: $purple!important
  background-color: $purple!important

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element
  background-color: $purple!important

.tasks-bg-color
  .mat-radio-button.mat-accent .mat-radio-inner-circle
    background-color: $lightGreen !important
    color: $lightGreen !important
  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element
    background-color: $lightGreen !important

.tasks-border-color
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle
    border-color: $lightGreen !important


// Modals
.modal
  z-index : $z-index-modal-popup

.modal-header
  background: none
  border: none
  padding-top: 2em
  padding-bottom: 0
  h1
    color: $text-black-color
    margin: auto
    font-size: 1em !important
  button
    background: none
    ion-icon
      color: $placeHolderGrey

.modal-cross
  color: $placeHolderGrey

.modal-footer
  border: none
  padding-top: 0


.mat-radio-label
    display: block!important

.mat-radio-container
    top: 0.3em!important

.mat-radio-inner-circle
  background-color: $teal!important

.mat-radio-outer-circle
  border-color: $teal!important

.mat-radio-disabled
    .mat-radio-outer-circle
      border-color: rgba(0,0,0,.38)!important
    .mat-radio-inner-circle
      background-color: rgba(0,0,0,.38)!important

// Used by PDF modal in mobile app
.dark-text-mobile
  .mat-radio-outer-circle
    border-color: $blue!important
  .mat-radio-inner-circle
    border-color: $blue!important
    background-color: $blue!important

// Nav tabs

.outer-tabs
  border-top: 1px solid #dee2e6!important
  border-left: 1px solid #dee2e6!important
  border-right: 1px solid #dee2e6!important
  margin-right: 0.5em

.nav-link.active, .nav-link:hover
  color: white!important
  background-color: $blue!important

.edit-form
  position: relative
  left: -1.09em
  overflow: show

// Customize Angular 2 MultiSelect plugin
.angular2-multiselect
  font-family: $font-family-sans-serif!important
  color: #343a40
  font-size: 0.9em

.angular2-multiselect .c-btn
  background: none !important

.angular2-multiselect .c-token
  background: $purple

.angular2-multiselect .c-label
  color: $dark !important

.angular2-multiselect .c-remove
  display: none

// Default text when nothing has been chosen
.angular2-multiselect .selected-list .c-btn :not(.c-most):not(.c-token)
  color: $teal

.list-area
  height: auto!important
  overflow: hidden!important
  .lazyContainer
    height: 150px!important

.pure-checkbox input[type="checkbox"]:checked + label:before
  background: $teal!important

.pure-checkbox input[type="checkbox"]:checked + label:before
  background: $teal!important

.pure-checkbox input[type=checkbox]+label:before
  border-radius: 0px !important
  border: 2px solid $teal !important
  width: 14px !important
  height: 14px !important

.dropdown-list.tagToBody
  position: absolute !important
  width: 100% !important
  top: unset !important
  left: unset !important

.c-btn
  box-shadow: none!important

.c-token
  background: $teal !important

.pure-checkbox label::before
  border-color: $teal !important

.c-token
  background-color: transparent!important
  padding: 0px!important

.badge-info-select
  color: #FFFFFF
  background-color: #009688
  font-size: 1.05em!important
  font-weight: 100

.quantity-select
  .c-token
    width: 100%!important
    margin-bottom: 0.35em!important
  .c-label
    float: none!important
  .badge
    width: 100%!important
  .fa-remove
    display: none!important

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element
  background-color: transparent!important

// Navbar
.custom-toggler.navbar-toggler
  border-radius: 0
  border: 0
  outline: 0
  &:active
    border: 0

.loader
  display: flex
  justify-content: space-between
  color: $purple
  margin-right: 10px
  margin-bottom: 10px

// Printable event style
.font-weight-bolder
  font-weight: bold!important

.d-print-block
  ul
    list-style: none!important

  .thumbnail
    position: relative!important
    width: 180px!important
    height: 101.25px!important
    overflow: hidden!important

.thumbnail
  img
    position: absolute!important
    left: 50%!important
    top: 50%!important
    height: 100%!important
    width: auto!important
    max-width: 180px
    -webkit-transform: translate(-50%,-50%)
    -ms-transform: translate(-50%,-50%)
    transform: translate(-50%,-50%)

.card
  border: none!important

.card-body
  overflow: hidden

.gallery
  padding: 20px !important
  overflow: auto

.mobile-gallery
  padding-bottom: 0
  padding-left: 0
  padding-top: 9px

.btn-row-fixed
  .btn-list
    display: flex

// Mobile view
@media screen and (max-width: $large)

  // Natives
  table
    overflow: scroll

  // Navbar
  .navbar
    padding: 0 !important
    h2
      padding-left: 16px
      margin-bottom: 0

  .username
    font-size: 0.9rem !important
  .space
    font-size: 0.8rem !important


  // Buttons
  .btn-row-fixed
    position: flex
    bottom: 10px
    z-index: $z-index-mobile-button
    right: 10px

  .btn-fixed-space
    margin-bottom: 60px

  .btn-left-on-mobile
    float: left !important

  .datatable-row-wrapper
    display: flex
    align-items: center !important

  .datatable-footer-inner
    height: 80px!important

@media screen and (max-width: $medium)
  .btn-list
    display: flex
    justify-content: left

@media screen and (max-width: $small)
  .btn-list
    display: flex
    justify-content: space-between

  .btn-row-fixed
    .btn-list
      display: flex
      li
        margin-top: 5px

/* ngx-datatable templating */
.ngx-datatable
  background-color: transparent
  /* Fixing issue with ngx-gallery full screen */
  transform: none !important

.datatable-header
  padding: 0.1em 0.3em 0 0.3em
  color: $black
  font-size: $font-size-2
  font-weight: bold
  background: $white
  border-radius: 6px 6px 0 0
  z-index: $z-index-datatable-body
  border-bottom: solid $darkWhite
  border-bottom-width: 1px

.ngx-datatable .datatable-body .datatable-scroll
  width: 100% !important

.datatable-body-row
  padding: 0.1em 0.3em 0em 0.3em

.datatable-body-cell
  display: flex!important
  align-items: center!important

.datatable-body-cell-label
  overflow: hidden
  text-overflow: ellipsis
  width: 100%

.not-scrollable
  .datatable-body.datatable-body
    overflow-x: hidden!important

.empty-row
  margin-top: 0.2em
  margin-bottom: 0.2em

ngx-datatable.ngx-datatable.bootstrap
  border-radius: 0 0 6px 6px
  .datatable-header .datatable-header-cell
    border-bottom: none
  .datatable-body
    background: $white
    border-radius: 6px 6px 0 0
    z-index: $z-index-datatable-body
    .datatable-row-wrapper
      cursor: pointer
      padding: 0.5em
      padding-left: 0
      border: 0 solid $darkWhite
      border-bottom-width: 1px
      &:hover
        background: $highlight-purple
      .datatable-body-row
        border: none
        &.datatable-row-even
          background: transparent
  .datatable-footer
    background: $white
    color: $black
    .pager
      li
        padding-left: 0.2em !important
        padding-right: 0.2em !important
        .active a
          text-decoration: underline !important
        a
          color: $black !important
          background-color: transparent !important
          font-weight: normal !important
          &:hover
            text-decoration: none !important
        &.disabled a
          cursor: auto
          opacity: 0.3

.activated-datatable
  ngx-datatable.ngx-datatable.bootstrap
    border-radius: 0 0 6px 6px
    .datatable-header .datatable-header-cell
      border-bottom: none
    .datatable-body
      background: $white
      border-radius: 6px 6px 0 0
      z-index: $z-index-datatable-body
      .datatable-row-wrapper
        cursor: pointer
        padding: 0.5em
        padding: 0px
        border: 0 solid $darkWhite
        border-bottom-width: 1px
        &:hover
          background: $highlight-purple
        .datatable-body-row
          border: none
          padding: 0px
          &.datatable-row-even
            background: transparent
    .datatable-footer
      background: $white
      color: $black
      .pager
        li
          padding-left: 0.2em !important
          padding-right: 0.2em !important
          .active a
            text-decoration: underline !important
          a
            color: $black !important
            background-color: transparent !important
            font-weight: normal !important
            &:hover
              text-decoration: none !important
          &.disabled a
            cursor: auto
            opacity: 0.3

.deactivated-datatable
  ngx-datatable.ngx-datatable.bootstrap
    border-radius: 0 0 6px 6px
    .datatable-header .datatable-header-cell
      border-bottom: none
    .datatable-body
      background: $white
      border-radius: 6px 6px 0 0
      z-index: $z-index-datatable-body
      .datatable-row-wrapper
        cursor: default
        padding: 0.5em
        padding: 0px
        border: 0 solid $darkWhite
        border-bottom-width: 1px
        &:hover
          background: $white
        .datatable-body-row
          border: none
          padding: 0px
          &.datatable-row-even
            background: transparent
    .datatable-footer
      background: $white
      color: $black
      .pager
        li
          padding-left: 0.2em !important
          padding-right: 0.2em !important
          .active a
            text-decoration: underline !important
          a
            color: $black !important
            background-color: transparent !important
            font-weight: normal !important
            &:hover
              text-decoration: none !important
          &.disabled a
            cursor: auto
            opacity: 0.3

.dashboard-datatable
  &.s-curve-event-datatable
    .datatable-body-row
      height: 76px !important
  ngx-datatable.ngx-datatable.bootstrap
    &.enlarged-table
      width: 100% !important
    width: 100% !important
    span
      text-align: center
    border-collapse: collapse
    .datatable-header
      border: 2px solid $darkWhite
      border-bottom: none
      padding: 0
      width: 100% !important

    .datatable-header-cell
      border-right: 2px solid $darkWhite
      font-size: 20px
      width: 100% !important
      &:last-child
        border-right: none
      div
        display: flex
        justify-content: center
    .datatable-body
      width: 100% !important
    .datatable-body-cell
      border-right: 2px solid $darkWhite
      width: 100% !important
      &:last-child
        border-right: none
      div
        display: flex
        justify-content: center

    .datatable-row-wrapper
      border: 2px solid $darkWhite
      border-bottom: none
      padding: 0
      &:last-child
        border-bottom: 2px solid $darkWhite

    .datatable-header-cell .datatable-body-cell
      &:not(:last-child)
        border-right: 2px solid $darkWhite

    .datatable-body-row
      padding: 0

.datatable-icon-sort-unset.sort-btn
  display: none !important

.datatable-footer-inner
  height: auto !important

.empty-row
  padding-bottom: 1em !important


.custom-paginator span
  font-size: 13px!important
  padding-top: 0.1em

.custom-paginator-element
  select
    color: $black !important
    border-color: $black !important

.logs-form
  width: 600px
  margin-top: 5%!important
  @media screen and (max-width: $medium)
    width: 90%

.full-h
  height: 100%

.full-w
  width: 100%

.accordion
  .card
    .card-header
      padding: 0!important
    .card-body
      padding: 0.8rem
    .accordion-content
      font-size: 0.8rem

.form-control
  font-size: 0.8rem
  height: $input-height
  &.form-input-md
    height: 38px

.form-control-md
  height: 40px !important

#btn-filter
  margin-bottom: 5px

// Remove ugly default :focus state on form buttons
.form-button:active,
.form-button:focus,
.form-button.active
  background-image: none
  outline: 0
  -webkit-box-shadow: none
          box-shadow: none

.zero-padding
  .mat-dialog-container
    padding: 0px !important
    max-width: 100%
    overflow-x: hidden

.top-button
  background: none
  color: $purple
  border-radius: 2px
  border: 1px solid $purple
  padding: .2em .6em .2em .6em
  min-width: 6em!important
  ion-icon
    margin-right: .4em

.filters
  width: 100%
  display: flex
  flex-wrap: wrap
  align-items: center
  margin-bottom: 0.2em !important
  .filter
    margin-right: 0.35rem
    margin-bottom: 0.35rem
    width: 12% !important
    min-width: 130px !important
    input
      padding: 0.25rem 0.5rem !important
    .c-btn
      padding: 7.6px 0.5rem !important
    @media screen and (max-width: 767px)
      min-width: 100%
      max-width: 100%

// Selector
.selected-list .c-list
  width: calc(100% - 40px)!important

// Accordion
.accordion
  .btn-link
    overflow: hidden
    text-overflow: ellipsis
    width: 100%
    text-align: left

  .card:last-of-type
    border: 1px solid rgba(0, 0, 0, 0.125)!important

.overflow-visible
  overflow: visible

@media print
  @page
    size: A4 !important
    margin-top: 0.25cm
    margin-left: 0.5cm
    margin-right: 0.5cm
    margin-bottom: 0.25cm

  img
    page-break-after: auto !important

  .weather-image
    height: 128px !important
    width: 128px !important
    object-fit: cover !important
    margin-right: 20px

  .header-print
    img
      height: 128px !important
      width: 128px !important
      object-fit: cover !important
      margin-right: 20px
    .main-title
      font-size: 1.2rem
      h1
        font-size: 3rem

  .title
    h1
      font-size: 2.5rem

  .details
    img
      height: 164px !important
      width: 164px !important
      object-fit: cover !important
      margin-right: 10px
      margin-bottom: 10px
  .box
    border: 1px solid #dee2e6
    padding: .3rem

  .table
    table-layout: fixed
    width: 100%
    & td.column-8
      overflow: hidden
      word-wrap: break-word
      white-space: nowrap
    & td.column-2
      overflow: hidden
      word-wrap: break-word
      white-space: nowrap


/** Color picker customization */
.btn-picker
  border-radius: 30px!important
  border-width: 0px!important

.mcc-color-picker-overlay
  margin-top: 1em!important


/** MOBILE STYLE (IONIC) */

ion-content.main-content
  height: 100%
  background-color: whitesmoke

.mobile-style
  ion-header
    position: sticky
    top: 0
    z-index: $z-index-header
  ion-content.below-header-content
     height: calc(100% - 44px) //44px is header height

ion-modal
  display: block!important
  height: 100%
  background-color: whitesmoke

app-ressource-modal
  justify-content: normal!important

app-select-gps-popup
  justify-content: normal!important

app-confirm-modal
  .primary-action-button
    &.tasks
      --background: #{$lightGreen}
    &.diary
      --background: #{$purple}
    &.administration
      --background: #{$brightOrange}

ion-row.body-content
  height: 100%

.split-pane-side
  height: 100%

.split-pane-main
  width: 100%
  height: 100%

ion-toolbar.toolbar-fixed
  top: 0

ion-title
  font-size: $font-size-3
  font-weight: bold

.scrollable-content
  margin-bottom: 2em

ion-item-divider
  font-size: $font-size-4
  z-index: $z-index-item-divider
  background-color: whitesmoke
  color: $purple
  border: none


ion-item-divider ion-label.sc-ion-label-md-s h1
  font-size: 18px
  font-weight: 700

// badges
ion-badge
  border-radius: 10px
  .close-button
    color: inherit
    background: none
    margin: -1px
    padding: 0
    height: 100% !important
    vertical-align: top
    box-sizing: border-box

.datetime-disabled
  opacity: 1!important

@media screen and (min-width: $small)
  .picker-md .picker-wrapper
    top: 0!important

// pop-ins
ngb-modal-backdrop.pop-in
  background-color: $teal
  &.show
    opacity: 0.2

ngb-modal-backdrop.pop-in.pop-in-tutorial.mobile-style
  background-color: $lightGrey
  &.show
    opacity: 1

ngb-modal-window.pop-in
  .modal-dialog
    box-shadow: none
    .modal-content
      border-radius: 6px !important
      background-color: $white
      box-shadow: 1px 1px 25px 2px rgba($teal, 0.6)
      border: none

  &.pop-in-small
    .modal-dialog
      max-width: 480px
  &.pop-in-tutorial
    .modal-dialog
      @media (min-width: 576px)
        max-width: 540px
      @media (min-width: 768px)
        max-width: 720px
      @media (min-width: 992px)
        max-width: 960px
      @media (min-width: 1200px)
        max-width: 1140px



//Specifically for android
.md .list-item.in-list.item
  border-bottom: 1px solid rgba($mediumGrey,0.3)
  border-top: 1px solid rgba($mediumGrey,0.3)

.autocomplete-list
  padding: 0.4em
  border-radius: 10px

.autocomplete-item
  font-size: 13px!important
  color: $mediumGrey

.autocomplete-item:last-child
  --border-width: 0px

.custom-back
  padding-right: .3em
  margin: 0 6px
  line-height: .67
  text-align: start
  ion-icon
    font-size: 1.4em
    font-weight: 400

.input-spe
  --padding-start: 9px!important

// Menu
.menu-icon
  padding: 0.3em
  border-radius: 50%

.menu-note
  padding-right: 0
  padding-top: 0.1em
  padding-bottom: 0.1em
  margin-right: 1em

.menu-content
  transform: none

.main-item.active
  --box-shadow: inset 5px 0 0 0 var(--main-item-highlight-color, #{$purple})
  --background: var(--main-item-highlight-background-color, #{$highlight-purple})
  ion-label
    color: var(--main-item-highlight-color, #{$purple})
    opacity: 1 !important
  ion-icon
    color: var(--main-item-highlight-color, #{$purple})

.main-item:not(.menu-label):hover
  --background: var(--main-item-highlight-background-color, #{$highlight-purple})

.main-item ion-icon
    color: $text-grey-color

ion-icon.cancel
  height: 1.2em!important
  width: 1.8em
  color: $text-grey-color

.border-bottom
  border-bottom: 1px solid #f8f8f8
  height: 3em

.border-top
  border-top: 1px solid #f8f8f8
  height: 3em

ion-label.placeholder-style
  font-size: 16px!important
  padding-left: 9px
  color: $teal

.load-text
  color: $purple!important
  font-variant: all-small-caps

.no-shadow input.searchbar-input.sc-ion-searchbar-md
  box-shadow: none!important
  border-radius: 10px

.no-shadow-desktop input.searchbar-input.sc-ion-searchbar-md
  box-shadow: none!important
  border-radius: 6px

.location-search input.searchbar-input.sc-ion-searchbar-md
  box-shadow: none!important
  border-radius: 15px
  background-color: rgba($borderGrey, 0.7)

ion-searchbar
  --color-icon: #{$placeHolderGrey}
  & > .searchbar-input-container > .searchbar-input
    box-shadow: none
    border-radius: 4px
    border: 1px solid $borderGrey

.searchbar-search-icon.sc-ion-searchbar-ios
  width: 16px !important
.searchbar-input.sc-ion-searchbar-ios
  font-size: 14px !important

.icon-x2
  font-size: 1.5em!important

.icon-x3
  font-size: 2.08em!important

.mobile-toaster
  width: 90%!important
  background-position-y: 19px!important
  background-size: 1.1em!important
  border-radius: 38px!important

.toast-bottom-center
  bottom: 1em!important
.right-separator
  border-right: 1px solid rgba($mediumGrey,0.3)!important

.aligned
  display: inline-block
  vertical-align: middle

.borderless
  --border-style: none!important

/** IOS SPECIFIC */
.ios-header
  z-index: $z-index-ios-header

.in-header.sc-ion-searchbar-ios-h
  max-height: 30px

//Remove default border bottom on last item of a list on ios
.list-ios:not(.list-inset):not(.list-ios-lines-none) .no-lines.item:last-child
  --border-width: 0
.list-ios:not(.list-inset):not(.list-ios-lines-none) .item:last-child
  --border-width: 0 0 0 0

.no-lines
  --inner-border-width: 0px

ion-title.title-ios
  padding: 0 75px

ion-title.padding-min.title-ios
  padding: 0 3em 0 1.3em !important

.small
  size: 50%

.my-tooltip
  white-space: pre-wrap

/** END OF IOS SPECIFIC */

ion-item
  textarea
    width: 100%
    border: none
    padding: 10px 10px 0px 10px
    resize: none
  textarea:focus, input:focus
    outline: none

.inner-border-0
  --inner-border-width: 0 !important

ion-icon
  stroke-width: 0

.actions-mobile
    display: flex
    align-items: center
    justify-content: center
    margin-bottom: 20px
    margin-top: 20px
    width: 100%
    button
      margin-right: 4px
      margin-left: 4px

/** END OF MOBILE STYLE (IONIC) */

.webapp-style ion-item
  --ion-item-background: transparent

.whitesmoke-background
  background-color: whitesmoke
  --background: whitesmoke

.white-background-color
  background: white

.cdk-global-overlay-wrapper, .cdk-overlay-container 
  z-index: 999999 !important

.cdk-overlay-connected-position-bounding-box
  z-index: 999999 !important

/** START OF NG-SELECT STYLE */
ng-select.ng-select
  flex: 1
  .ng-select-container
    ion-badge
      margin: 0.3em
      white-space: normal
      .close-button
        color: inherit
    label
      width: auto
      font-weight: normal
      margin: 0
      font-size: 0.8em !important
    .close-button
      color: $text-grey-color
      background: none
      padding: 0
      ion-icon
        vertical-align: middle
    .ng-value-container
      .ng-value:last-child
        margin-right: 1em
      .ng-placeholder
        color: $text-grey-color
        order: 1
        width: 0
        height: 0
        white-space: nowrap
        position: initial !important
        margin-top: 0.2em
      .ng-input
        order: 2
        line-height: 1.8em
        flex: initial
        input
          cursor: pointer
  .ng-clear-wrapper, .ng-arrow-wrapper
    display: none
  .ng-dropdown-panel
    background-color: $lighterGrey
    border-radius: 0 0 4px 4px
    box-shadow: 1px 4px 6px $light-shadow-color
    .ng-dropdown-header
      margin: 0.1em 0.5em 0.3em 0.8em
      border-bottom: 1px solid $lightGrey
      label
        color: $text-black-color
        font-weight: bold
    .ng-option
      &:hover
        background-color: $lightGrey
      & > div
        padding: 0.3em 0.3em 0.3em 0.8em
        width: 100%
        height: 100%
      span
        & > ion-icon
          vertical-align: middle
        & > input[type='checkbox']
          vertical-align: sub
  &.ng-select-single
    .ng-select-container .ng-value-container
      .ng-input
        position: initial
      .ng-value
        display: flex
        flex-flow: row nowrap
        align-items: center
        padding-right: 1em
        overflow: visible
        span
          margin-right: 1em
    .ng-option-selected
      font-weight: bold
  &.ng-select-opened
    .ng-input
      background-color: $lighterGrey
      box-shadow: 2px 3px 4px $light-shadow-color
      border-radius: 4px 4px 0 0
  &.full-width-labels
    .ng-select-container
      .ng-value-container
        display: flex
        flex-direction: column
        flex-wrap: nowrap
        justify-content: left
        span
          white-space: normal
          flex: 1
        .spacer
          flex: 10
        .ng-value, .ng-value > div
          display: flex
          flex-direction: row
          flex-wrap: nowrap
          justify-content: space-between
          align-items: center
        .ng-value > div
          width: 100%
    .selected-element-background
      margin-right: 0
      margin-bottom: 0.5em

  .selected-element-background
    background: rgba($pill,0.34)
    border-radius: 14px
    padding: 0.1em 01em 0.1em 1em
    margin-right: 0.5em
    margin-top: 0.5em

    label
      color: $dark
      margin-right: 1.5em !important
      input
        color: $text-black-color
.country-select
  height: $input-height
  padding: 0.375rem 0.75rem
  font-size: 13px
  line-height: 1.5
  border-radius: 0.25rem
  border: 1px solid $border-grey
  .ng-dropdown-panel
    height: 255px
    margin-top: 1.5em
    margin-bottom: 1em
    left: 0em
  .ng-has-value .ng-placeholder
    display: none

.site-task-form
  mat-progress-bar
    width: 100% !important
    .mat-progress-bar-fill::after
      background-color: $lightGreen
  .create-task-form
    ng-select.ng-select
      &.ng-select-opened
        .ng-input
          margin-top: 0px
      background-size: 25px
      padding: 6px 10px 8px 12px
      .ng-arrow-wrapper
        display: inline-block
        margin-top: 4px
        margin-right: 3px
        color: grey
        .ng-arrow
          display: none
      span
        &.ng-arrow-wrapper
          &:before
            content: "\f078"
            display: inline-block
            font: normal normal normal 14px/1 FontAwesome
            font-size: inherit
            text-rendering: auto
      .ng-value-container
        .ng-placeholder
          text-decoration-line: underline
          text-decoration-thickness: 1px
          color: darkgrey
        &.ng-has-value
          .ng-placeholder
            padding-top: 0
      .ng-select-container
        &.ng-has-value
            .ng-input
                margin-top: 0
      #selected-element
        border-radius: 4px
  #contractors-wrapper, #assignees-wrapper
    ng-select.ng-select
      .selected-element-background
        background-color: #8F94A3
        color: white
      .ng-value
        > div
          margin-top: 0px !important
      .ng-select-container
        &.ng-has-value
          .ng-placeholder
            padding-top: 10px
            position: relative !important
            top: -5px
            padding-left: 5px
        .close-button
          padding-left: 2px
          color: white
          ion-icon
            height: 13px
  ng-select.ng-select
    background-color: $whitesmoke !important
    border-radius: 6px
    padding: 5px 2px 5px 5px
    &.ng-select-opened
      .ng-input
        background-color: $whitesmoke
        box-shadow: none
        border-radius: 0
        margin-top: 5px
    .ng-dropdown-panel
      margin-top: 10px
      left: 0px
    .ng-select-container
      .ng-value-container
        .ng-input
          > div.placeholder
                color: red
                font-size: 35px
                font-family: MontserratRegular
    .ng-value
      padding: 5px 5px 0 0
      > div
        width: 100% !important
    .ng-select-container
      &.ng-has-value
        .ng-placeholder
          padding-top: 10px
        .ng-input
          padding-top: 5px

/** END OF NG-SELECT STYLE */



/** DATEPICKER THEMING */
.ngb-dp-weekday
  color: $purple!important
/** END OF DATEPICKER THEMING */


/** FIREFOX SPECIFIC STYLE */

@-moz-document url-prefix()
  input[type=number]:read-only
    // Remove number spinner
    -moz-appearance: textfield

  input[type=date]:read-only, input[type=time]:read-only
    // Remove clear date button
    clip-path: inset(0 17px 0 0)

/** END OF FIREFOX SPECIFIC STYLE */

/** TABLET SPECIFIC */
// Fix for ion-modal on tablets
ion-modal
  ion-backdrop
    visibility: visible
    opacity: 0!important
    z-index: $z-index-modal-backdrop
  .modal-wrapper
    width: 100% !important
    height: 100% !important
    position: absolute
    overflow: hidden
/** END OF TABLET SPECIFIC */

/** IPHONE X SPECIFIC */

// Fix top content hidden by "notch"
ion-header
  ion-toolbar
    padding-top: constant(safe-area-inset-top)!important
    padding-top: env(safe-area-inset-top)!important

// Fix fab footer buttons overlapped by iPhone X home button
.fab-vertical-bottom
  margin-bottom: constant(safe-area-inset-bottom)!important
  margin-bottom: env(safe-area-inset-bottom)!important

/** END OF IPHONE X SPECIFIC */

/** PROFILE PICTURE */
.profil-pic
  display: flex
  align-items: center
  justify-content: center
  padding: 5px
  font-size: 1.5em !important
  border-radius: 50%

.dark-background
  color: $purple
  background-color: $white

.profil-size
  height: 50px
  width: 50px

.icon-size
  height: 35px
  width: 35px

.white-background
  color: $purple
  background-color: $light-shadow-color
/** END OF PROFILE PICTURE */

.status-button
  max-width: 18em!important
  ion-button
    font-size: 100%
    text-transform: none
    --box-shadow: none
    --padding-start: 0.7em
    --padding-end: 0.7em
    --padding-top: 1.3em
    --padding-bottom: 1.3em
    --border-radius: 0.2em

/** NGX VIEWER customization **/
.viewer-button
  height: 100px!important
  width: 100px!important
.viewer-button::before
  bottom: 25px!important
  left: 25px!important
/** END OF NGX VIEWER **/
.low-opacity
  opacity: 0.45
  font-weight: normal

.icon-detail
  display: flex
  align-items: center
  color: $mediumGrey
  padding-top: 0.2em
  --min-height: 24px!important
  ion-icon
    font-size: 1.2em!important
  ion-note
    font-size: 0.8em
    color: $mediumGrey
    font-weight: bold
    margin-right: 10px

.w10
  width: 10%

.w20
  width: 20%

.w30
  width: 30%

.w40
  width: 40%

.w50
  width: 50%

.w60
  width: 60%

.w70
  width: 70%

.w80
  width: 80%

.w90
  width: 90%

.w100
  width: 100%

.wauto
  width: auto

\:root
  --task-menu-color: #{$lightOrange}
  --task-menu-background-color: #{rgba($lightOrange, 0.07)}
  --event-menu-color: #{$red}
  --event-menu-background-color: #{rgba($red, 0.07)}

  --menu-whitesmoke-background-color: #f5f5f5
  --menu-orange-background-color: #{rgba($brightOrange, 0.07)}
  --site-diary-color: #{$purple}
  --site-task-color: #{$lightGreen}
  --administration-page-color: #{$brightOrange}

.status-badge
  font-size: $font-size-5
  font-weight: 500
  color: white !important
  overflow-wrap: normal
  margin: 0.2em
.tag-badge
  font-size: $font-size-4
  font-weight: 400
  background-color: $bright-purple
  color: white !important
  overflow-wrap: normal
  margin: 0.2em
  &:before
    content: "#"
.tag-pill
  color: $pill-text
  background-color: $bright-purple
  border-radius: 14px
  padding: 4px 9px
  text-align: center
  overflow: hidden
  height: 26px
  line-height: normal
.tag-not-selected
  background-color: $mediumGrey

.fab-btn
  position: fixed
  z-index: $z-index-fab-button
  &.desktop
    bottom: 3.8em
    right: 3.8em
  &.mobile
    bottom: 1em
    right: 1.3em

img.thumbnail
    width: $small-picture
    height: $small-picture
    border-radius: 5px
    object-fit: cover
    &.big
      width: $big-picture
      height: $big-picture
    &.new-image
      border: 2px solid rgba($success, 0.4)


/** Fullcalendar customization **/

.fc-timeline-event
  border-radius: 5px !important
  margin-top: 5px !important
  margin-bottom: 5px !important
  margin-left: 2px !important
  margin-right: 2px !important

.fc-toolbar-chunk
  display: flex !important
  h2
    margin-left: 10px !important
    padding-top: 3px !important

.fc-timeline-slot-cushion.fc-scrollgrid-sync-inner
  pointer-events: none !important

.fc-timeline-slot.fc-timeline-slot-lane.fc-day
  border-left: none !important
  border-right: none !important

.fc-toolbar-chunk
  font-size: 0.8rem !important
  button
    font-size: 0.8rem !important
    &.fc-prev-button
      margin-top: 2px
      color: inherit !important
      border: inherit !important
      box-shadow: none !important
      background-color: inherit !important
    &.fc-next-button
      margin-top: 2px
      color: inherit !important
      border: inherit !important
      box-shadow: none !important
      background-color: inherit !important


/** End of Fullcalendar customization **/


.site-task-report-progress
  ng-select.ng-select
    background: unset !important
    padding: 0 !important
  .ng-value
    padding: 0
    #selected-element
      width: 100%
  .selected-element-background
    background-color: white !important
    border: 1px solid lightgrey !important
    border-radius: 5px !important
  .ng-input, .ng-placeholder, .ng-dropdown-panel, .close-button
          display: unset !important
  .ng-input
    margin-top: 0 !important
  .main-form-wrapper
    &:not(.editable)
      .ng-select
        .ng-input, .ng-placeholder, .ng-dropdown-panel, .close-button
          display: none !important

.site-diary-events-list-webapp
  .datatable-body-row
    padding: 0.5em
    border: 0 solid $darkWhite !important
    border-bottom-width: 1px !important
    &:hover
      background: $highlight-purple
  .datatable-row-even
    &:hover
      background: $highlight-purple !important
  .datatable-row-odd
    &:hover
      background: $highlight-purple !important
  .datatable-row-wrapper
    padding: 0 !important
    border: none !important
    &:hover
      background: transparent !important

.site-task-list
  .datatable-header
    color: $black
    background: $white
    border-radius: 6px 6px 0 0
    z-index: $z-index-datatable-body
    border-bottom: solid $darkWhite
    border-bottom-width: 1px
  .datatable-body-row
    border: 0 solid $darkWhite !important
    border-bottom-width: 1px !important
    &:hover
      background: $highlight-purple
  .datatable-row-even
    &:hover
      background: $highlight-purple !important
  .datatable-row-odd
    &:hover
      background: $highlight-purple !important
  .datatable-row-wrapper
    padding-top: 0 !important
    padding-bottom: 0 !important
    padding-left: 0 !important
    border: none !important
    &:hover
      background: transparent !important
  .datatable-body-row
    padding: 0
    height: 65px !important
  .datatable-body-cell
    height: 65px !important
    padding-top: 0 !important
    padding-bottom: 0 !important
  .cuppa-dropdown
    padding: 5px
  .datatable-footer
    color: $black !important
    .custom-paginator-element
      select
        color: $black !important
        border-color: $black !important
  .datatable-pager
    color: $black !important
  ngx-datatable.ngx-datatable.bootstrap
    .datatable-footer
      .pager
        li
          a
            color: $black !important
  mat-progress-bar
    width: 100% !important
    .mat-progress-bar-fill::after
      background-color: $lightGreen

.view-all-tasks-alert-box
  --max-width: 500px
  --width: 500px
  .alert-wrapper
    border-radius: 8px
    .cancel-btn
      border: 1px solid $borderGreyishBlue
      color: black
      border-radius: 4px
    .confirm-btn
      border: 1px solid $lightGreen
      background-color: $lightGreen
      color: white
      border-radius: 4px

.request-otp-alert-box
  --max-width: 500px
  --width: 500px
  .alert-wrapper
    border-radius: 8px
    .logout-btn
      border: 1px solid $borderGreyishBlue
      color: black
      border-radius: 4px
    .confirm-btn
      border: 1px solid $brightOrange
      background-color: $brightOrange
      color: white
      border-radius: 4px

.request-otp-alert-box-mobile
  --width: auto
  .alert-wrapper
    border-radius: 8px
    .alert-head
      text-align: center
    .alert-message
      text-align: center
    .alert-button-inner
      justify-content: center
    .alert-button-group
      display: flex
      flex-direction: column-reverse
    .logout-btn
      color: black
    .confirm-btn
      border: 1px solid $purple
      background-color: $purple
      color: white

.no-site-locations-alert-box
  --max-width: 500px
  --width: 500px
  .alert-wrapper
    border-radius: 8px
    .alert-button-group-vertical
      flex-direction: row
    .cancel-btn
      border: 1px solid #C5C5C7
      color: black
      border-radius: 4px
    .confirm-btn
      border: 1px solid $lightGreen
      background-color: $lightGreen
      color: white
      border-radius: 4px

.site-task-preview-datatable
  ion-icon
    pointer-events: none
  .datatable-body
    width: 100% !important
  .btn-fixed-space
    margin-bottom: 10px
  .ngx-datatable
    font-size: 12px
  .datatable-header
    color: $teal
    border-bottom: none
    .datatable-row-center
      background-color: transparent
      border-bottom-width: 0
      &:hover
        background-color: transparent !important
  .datatable-row-wrapper
    border-bottom-width: 0 !important
    cursor: none !important
    background-color: transparent !important
    &:hover
      background: transparent !important
  .datatable-body-row
    background-color: whitesmoke !important
    padding: 0 !important
    &:hover
      background-color: $highlight-darker-purple !important
    &.datatable-row-even
      width: 100% !important
      border-radius: 4px
      cursor: default !important
    &.datatable-row-odd
      width: 100% !important
      border-radius: 4px
      cursor: default !important
  .datatable-row-center
    @media screen and (max-width: 1350px)
      width: auto !important
      background: inherit !important
      &:hover
        background: $highlight-purple !important
    @media screen and (min-width: 2000px)
      width: auto !important
    width: 100% !important
    cursor: pointer

  @media screen and (max-width: 1350px)
    .datatable-body-row:hover
      background: none !important

.site-task-preview-view
  .subtask-detail-datatable
    .datatable-header
      width: auto !important
    .datatable-header-inner
      width: auto !important
    .datatable-row-center
      display: flex
      justify-content: space-evenly
    .datatable-row-group
      display: flex
      justify-content: space-evenly
    .datatable-body-cell
      padding-top: 0.50rem !important
      padding-bottom: 0.50rem !important
  .remove-subtask-button
    text-align: right !important
    padding: 0 !important
    button
      background: none
      font-size: 18px
      padding-right: 6px
  mat-progress-bar
    width: 100% !important
  .ng-input, .ng-placeholder, .ng-dropdown-panel, .close-button
        display: unset !important
  .assignees, .contractors-section
    ng-select.ng-select
      background-color: white !important
      border: 1px solid $border-lightGrey
      .ng-select-container .close-button
        color: white
        margin-left: 5px
      .selected-element-background
        background-color: #8F94A3
        border-radius: 14px
        color: white
        padding-right: 0
        padding-top: 0
        padding-bottom: 0
      .ng-has-value .ng-value-container .ng-input
        margin-top: 5px
    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder
      z-index: 10
      cursor: pointer
    .ng-select-opened .ng-placeholder
      z-index: 1 !important
    ng-select.ng-select .ng-select-container.ng-has-value .ng-input
      background-color: white
    ng-select.ng-select .ng-select-container.ng-has-value .ng-placeholder
        position: relative !important
        top: -5px
    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input
      background-color: white
      padding-top: 0
    ng-select.ng-select .ng-value
      padding-top: 0px
  ng-select.ng-select
    background-color: white
    &.ng-select-opened
      .ng-input
        margin-top: 0
    .selected-element-background
      background-color: #EBEDEF
      border-radius: 4px
      padding-top: 5px
      padding-bottom: 5px

.light-green-bg
  background-color: $lightGreen !important
  border-color: $lightGreen !important

// Custom styled checkboxes on web for showing user's app access
 
.line-container
  .mat-checkbox-ripple .mat-ripple-element
    background-color: $brightOrange !important


  .mat-checkbox-checked.mat-accent .mat-checkbox-background
    background-color: $brightOrange !important


  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background
    background-color: $brightOrange !important

// Custom styled checkboxes on mobile for showing user's app access

.mobile-container
  .mat-checkbox-ripple .mat-ripple-element
    background-color: $purple !important


  .mat-checkbox-checked.mat-accent .mat-checkbox-background
    background-color: $purple !important


  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background
    background-color: $purple !important

/** Start of Custom Form Creator **/

/** Ion Alert dialog inside Custom Form Creator **/
.custom-form-alert-box
  --max-width: 500px
  --width: 500px
  .alert-wrapper
    border-radius: 8px
    .cancel-btn
      border: 1px solid #C5C5C7
      color: black
      border-radius: 4px
    .remove-btn
      border: 1px solid #FF0000
      background-color: #FF0000
      color: white
      border-radius: 4px

.custom-form-field
  ng-select.ng-select .ng-select-container.ng-has-value .ng-placeholder
    position: relative !important
    top: 6px
  #custom-tag-field
      ng-select.ng-select .ng-select-container.ng-has-value .ng-placeholder
        top: 4px
  .ng-has-value .ng-value-container .ng-input
    input
      margin-top: 7px
      &#tags
        margin-top: 4px

.custom-form-loading-bar
  mat-progress-bar
    .mat-progress-bar-fill::after
      background-color: $brightOrange

/** End of Custom Form Creator **/

.app-access-upgrade-alert-box
  --max-width: 500px
  --width: 500px
  .alert-wrapper
    border-radius: 8px
    .ok-btn
      color: black

.site-archived-alert-box
  --max-width: 500px
  --width: 500px
  .alert-wrapper
    border-radius: 8px
    .ok-btn
      color: black

@mixin inverted-filter-dialog
  .arrow-up
    position: absolute !important
    right: 5%
  .list-area
    position: absolute !important
    right: 0%

.filter
  @media (min-width: 1417px)
    .tasks
      @include inverted-filter-dialog
  @media (min-width: 1280px) and (max-width: 1417px)
    .states
      @include inverted-filter-dialog
  @media (min-width: 1171px) and (max-width: 1280px)
    .creators
      @include inverted-filter-dialog
  @media (min-width: 984px) and (max-width: 1171px)
    .locations
      @include inverted-filter-dialog
  @media (min-width: 843px) and (max-width: 984px)
    .tags
      @include inverted-filter-dialog
  @media (min-width: 707px) and (max-width: 843px)
    .contractors
      @include inverted-filter-dialog
  .lazyContainer
    min-height: 200px
  .list-area
    width: 150%

.space-access
  angular2-multiselect
    position: absolute !important
    top: 22%
  .c-remove
    display: none
  .cuppa-dropdown
    border-radius: 5px
  .dropdown-list.tagToBody
    position: absolute !important
    width: 150px !important
    border-radius: 5px
    .arrow-down
      border: 0
  .list-area
    border-radius: 5px
    box-shadow: none
    .lazyContainer
      height: unset !important
      overflow: hidden
  .selected-list
    .c-angle-down
      width: 9px !important
    .c-angle-up
      width: 9px !important
    .c-btn
      color: $black
      width: 150px
      border-radius: 5px
    .c-list
      .c-token
        width: 100%
        .c-label
          color: $black
          font-size: 13px
    .c-angle-down
      width: 9px !important
  .arrow-up
    border-bottom: 15px solid transparent
  .dropdown-list ul li
    &:hover
      background: $brightOrange
      color: red !important
      input[type=checkbox]+label
        color: white
      input[type=checkbox] + label:before
        border-color: $white !important
  .selected-item
    background: $brightOrange
    width: 150px
  .selected-list .c-angle-down svg path
    stroke: $black
    stroke-width: 40px
  .selected-list .c-angle-up svg path
    stroke: $black
    stroke-width: 40px
  .pure-checkbox input[type="checkbox"]:checked + label
    color: $white
  .pure-checkbox input[type="checkbox"]:checked + label:before
    background: $white !important
    border-color: $white !important
  .pure-checkbox input[type=checkbox] + label:before
    border-color: $teal !important
  .pure-checkbox input[type="checkbox"]:checked + label::after
    background: $white !important
    border-color: $brightOrange

.role-disabled-row
  padding-bottom: 1rem

.ngx-datatable-custom-rows
  &.is-last-row
    margin-bottom: 6rem !important

.task-dependency-popup
  mat-progress-bar
    .mat-progress-bar-fill::after
      background-color: $lightGreen
  ion-searchbar
    --color-icon: #{$black}
    & > .searchbar-input-container > .searchbar-input
      box-shadow: none
      border-radius: 4px
      border: 1px solid $solitude
      background: $solitude
  .dropdown-toggle
    width: auto
    border: 1px solid $black
    text-align: left
  .dropdown-toggle::after
    margin-left: 50px
  .cuppa-dropdown
    padding: 0 5px 0 5px
  .dropdown-menu
    min-width: 14rem
  .dropdown-list.tagToBody
    width: 95% !important

.overlay-task-dependency-popup
  background: $black
  opacity: 0.75 !important

.task-dependency-popup-panel
  mat-dialog-container
    display: flex
    flex-direction: column
    justify-content: space-evenly

.overlay-successor-tasks-delete-popup
  background: $black !important
  opacity: 0.75 !important

.successor-tasks-delete-popup
  mat-progress-bar
    .mat-progress-bar-fill::after
      background-color: $lightGreen

.stepper-container
  .mat-step-header .mat-step-icon
    background-color: $brightOrange
  .mat-horizontal-stepper-header::after
    border-top-color: $brightOrange
  .mat-stepper-horizontal-line
    border-top-color: $brightOrange
  .mat-horizontal-stepper-header:not(:first-child)::before
    border-top-color: $brightOrange
  .mat-step-header:hover
    background-color: $white
  .mat-step-header.cdk-keyboard-focused
    background-color: $brightOrangeLight
  .mat-step-header.cdk-program-focused
    background-color: $brightOrangeLight
  .mat-step-header[aria-selected="true"]
    background-color: $brightOrangeLight !important

/* ngx-viewer style */
.ngx-viewer-move-webapp
  margin-left: auto !important
  width: auto !important
  height: 90% !important
  margin-top: 2.5% !important

.faulty-tasks-list
  mat-progress-bar
    width: 100% !important

.faulty-screen-toolbar
  ion-title
    font-size: 1.25em
    font-weight: bold

/* Otp verification modal region */

.otp-verification-modal-webapp
  .modal-content
    border-radius: 12px

.otp-verification-component
  .modal-content
    border: none
    border-top-left-radius: 8px
    border-top-right-radius: 8px
    .mobile
      ion-header
        border-top-left-radius: 8px
        border-top-right-radius: 8px
        ion-toolbar
          border-top-left-radius: 8px
          border-top-right-radius: 8px


/* End otp verification modal region */

/* Phone number verification modal region */

.phone-number-verification-modal
  &.webapp
    .modal-content
      border-radius: 12px

.phone-number-verification-component
  .modal-content
    border: none
    border-top-left-radius: 8px
    border-top-right-radius: 8px

/* End phone number verification modal region */

/* ngx-intl-tel-input region */

ngx-intl-tel-input
    padding: 0 1px 0 1px !important
    border-radius: 4px
    .iti--allow-dropdown
      width: 100%
      height: 100%
      input
        border: none
        width: 100%
        height: 100%
        &:focus
          border: none
    &.register-form
        input
          color: $black
          font-size: $font-size-5
          line-height: 1.5
          min-width: $login-form-input-min-width
          max-width: $login-form-input-max-width
          width: 100%
          border: 1px solid $teal
          border-radius: 4px
          padding: 6px 12px
          &:focus
            box-shadow: 0 0 0 0.2rem rgba($purple, 0.25)
        .iti__selected-flag
          font-size: 0.8rem
/* end ngx-intl-tel-input region */

.profile-screen
  .card-body
    overflow: visible

/* general alert box region */

.general-alert-box
  --max-width: 500px
  --width: 500px
  .alert-wrapper
    border-radius: 8px
    .secondary-btn
      border: 1px solid $borderGreyishBlue
      color: black
      border-radius: 4px
    .confirm-btn
      border: 1px solid
      background-color: $white !important
      border-radius: 4px
      &.administartion-color
        color: $brightOrange
        border-color: $brightOrange
      &.diary-color
        color: $purple
        border-color: $purple
      &.tasks-color
        color: $lightGreen
        border-color: $lightGreen

/* end general alert box region*/

/* notifications region*/

.notifications-screen-mobile
  background-color: whitesmoke
  ion-title
    font-size: 18px

.notification-screen-webapp
  top: 3em
  right: 6.4em
  width: 540px !important
  position: absolute
  height: 780px
  background-color: whitesmoke
  border-radius: 8px
  .header
    display: flex
  .toolbar
    height: 4rem
    display: flex
    justify-content: space-between
    align-items: center
    border-radius: 8px

/* end notifications region*/

/* import resources duplicate items error alert box region*/

.import-resources-duplicate-items-error-alert
  --max-width: 450px
  --width: 450px
  .alert-head
    text-align: initial
  .alert-sub-title
    font-size: 17px
    color: black
    font-weight: 600
  .alert-message
    padding: 0
    font-size: 17px
    font-weight: 600
    padding-left: 16px
    text-align: initial
    margin-top: 5px
    border-bottom: 0.55px solid rgba(black, 0.2)
  .alert-input-group
    overflow: auto
    height: 250px
    input
      border: none
      background: inherit
      color: black
      font-size: 14px
      opacity: 1
      border-bottom: 0.55px solid $placeHolderGrey
      border-radius: 0
  .alert-button-group
    button
      &.is-administration-page
        color: $brightOrange
      &.diary
        color: $purple
      &.tasks
        color: $lightGreen

/* end import resources duplicate items error alert box region*/

/* image annotation add new text alert box */
.add-new-text-box
  .alert-wrapper
    .text-input
      height: 100px

/* end image annotation add new text alert box */

/* Notification settings region */
.notification-settings-webapp
  .custom-switch
    &.tasks
      .custom-control-input:checked ~ .custom-control-label::before
        background-color: $lightGreen
        border-color: $lightGreen
    &.diary
      .custom-control-input:checked ~ .custom-control-label::before
        background-color: $purple
        border-color: $purple

/* End notification settings region */

.information-tag-screen
  .datatable-row-wrapper
    cursor: default !important

/* mobile ngx image viewing */
.ngx-image-viewing-mobile
  .viewer-footer
    .viewer-toolbar
      position: fixed
      width: 90%
      margin-left: 5%
      margin-right: 5%
      top: 45%
      ul
        display: flex
        justify-content: space-between
        li
          width: 40px
          height: 40px
          display: flex
          justify-content: space-around
          align-items: center
          background-color: #7d7d7d
          opacity: 0.7
          transition: 0.15s
          &:hover
            opacity: 0.9
  .viewer-canvas
    img
      bottom: 28%
      height: 90%
      width: 90%
  .viewer-button
    right: 78% !important
    background-color: transparent !important
  &#event-preview-mobile-id
    filter: blur(5px)
  &.viewer-container
    height: 65%
    margin-top: 36%
    background-color: black
  #image-preview-dropdown
    width: 47%
    left: 50%
    position: relative
    top: 10%
    --ion-item-background: #262626
    p
      color: #EDF3F1
    ion-icon
      color: #EDF3F1
      margin-right: 10px
      height: 20px
      width: 20px
  .toolbar
    height: 44px
    position: absolute
    z-index: 9999999
    background: black
    width: 100%
    top: 0%
      
.disable-scroll
  overflow-y: hidden !important

/* End mobile ngx image viewing */

/* webapp ngx image viewing */
.ngx-image-viewing-webapp
  .viewer-footer
    .viewer-toolbar
      position: fixed
      width: 90%
      margin-left: 5%
      margin-right: 5%
      top: 45%
      ul
        display: flex
        justify-content: space-between
        li
          width: 80px
          height: 80px
          display: flex
          justify-content: space-around
          align-items: center
        .viewer-prev
          &:before
            display: none
          &:after
            font: normal normal normal 14px/1 FontAwesome
            content: "\f104"
            font-size: 50px
            color: white
            width: 50px
            height: 50px
        .viewer-next
          &:before
            display: none
          &:after
            font: normal normal normal 14px/1 FontAwesome
            content: "\f105"
            font-size: 50px
            color: white
            width: 50px
            height: 50px

/* End webapp ngx image viewing */

/* Start onboarding*/
.tour-container
  .description
    ul
      font-size: 12px
      padding-left: 20px
      margin-bottom: 0.5em
    p
      margin-top: 0.5em
      margin-bottom: 0.5em
      font-size: 12px
    .content-footer
      margin-top: 1em !important
      margin-bottom: 0
    .margin-1
      margin-top: 1em !important
      margin-bottom: 1em !important

.admin-color
  color: $brightOrange !important
  cursor: pointer

.site-diary-color
  color: $purple !important
  cursor: auto
  font-size: 20px

.site-task-color
  color: $lightGreen !important
  cursor: auto
  font-size: 20px

/* End onboarding*/

/* Dashboard region */

.dashboards-component
  width: max-content
  .dashboard-filter
    .dropdown-list.tagToBody
      height: 85% !important
    .list-area
      height: 100% !important
      .lazyContainer
        height: 216px !important
  .dashboard-admin-filter
    .dropdown-list.tagToBody
      height: 85% !important
    .list-area
      height: auto!important
      overflow: hidden!important
      .lazyContainer
        height: 180px !important
  .dropdown-list.tagToBody
    position: fixed !important
    display: block !important
    right: 0
    padding: 0
  .selected-list
    display: none
  .filter-select-all
    display: none
  .list-area
    box-shadow: none
    border: none
  .arrow-up
    display: none
  .dropdown-header
    padding: 0.5rem 1rem
  .google-visualization-table
    .table-cell
      text-align: center !important
    th
      text-align: center !important
      height: 50px !important

/* End of dashboard region */

/* Start subscription page expansion panel */

.faq-container
  .mat-expansion-panel-content
    .mat-expansion-panel-body
      padding: 0px !important

.select-field
  .mat-select-min-line
    font-size: 16px !important
/* End subscription page expansion panel */
