
/** Ionic CSS Variables **/
:root {

  /** primary **/
  --ion-color-primary: #4c27ba;
  --ion-color-primary-rgb: 106,145,198;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #4322a4;
  --ion-color-primary-tint: #BEB0E4;

  /** secondary **/
  --ion-color-secondary: #9290a7;
  --ion-color-secondary-rgb: 0,150,136;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #008478;
  --ion-color-secondary-tint: #1aa194;

  /** tertiary **/
  --ion-color-tertiary: #ffffff;
  --ion-color-tertiary-rgb: 44,56,74;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #8b8b8ba6;
  --ion-color-tertiary-tint: #414c5c;

  /** success **/
  --ion-color-success: #529c6b;
  --ion-color-success-rgb: 76,175,80;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #45c3b7;
  --ion-color-success-tint: #47d3b2;

  /** light green **/
	--ion-color-light-green: #14A735;
	--ion-color-light-green-rgb: 20,167,53;
	--ion-color-light-green-contrast: #ffffff;
	--ion-color-light-green-contrast-rgb: 255,255,255;
	--ion-color-light-green-shade: #12932f;
	--ion-color-light-green-tint: #2cb049;

  /** warning **/
  --ion-color-warning: #daa636;
  --ion-color-warning-rgb: 245,171,53;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #d8962f;
  --ion-color-warning-tint: #f6b349;

  /** danger **/
  --ion-color-danger: #dd1848;
  --ion-color-danger-rgb: 244,67,54;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #ad1340;
  --ion-color-danger-tint: #df2858;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,34,34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,244,244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** Toolbar **/
  $toolbar-ios-title-font-size: 1.2rem!important;
  $toolbar-md-title-font-size: 1.5rem!important;
  $toolbar-wp-title-font-size: 1.2rem!important;

  /** Input **/
  --ion-placeholder-text-color: #9290A7;


  // Tag chip/pill color
  --ion-color-chip: #c5b9e9;
  --ion-color-chip-rgb: 197,185,233;
  --ion-color-chip-contrast: #fff;
  --ion-color-chip-contrast-rgb: 0,0,0;
  --ion-color-chip-shade: #ada3cd;
  --ion-color-chip-tint: #cbc0eb;

}

.ion-color-white {
  --ion-color-base: #ffffff;
  --ion-color-base-rgb: 255,255,255;
  --ion-color-contrast: #000;
  --ion-color-contrast-rgb: 255,255,255;
  --ion-color-shade: #000;
  --ion-color-tint: #000;
}

.ion-color-black {
  --ion-color-base: #000000;
  --ion-color-base-rgb: 0,0,0;
  --ion-color-contrast: #fff;
  --ion-color-contrast-rgb: 0,0,0;
  --ion-color-shade: #fff;
  --ion-color-tint: #fff;
}

.ion-color-blue {
  --ion-color-base:  #003ef6;
  --ion-color-base-rgb: 0,62,246;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255,255,255;
  --ion-color-shade: #0037d8;
  --ion-color-tint:  #1a51f7;
}


.ion-color-chip {
  --ion-color-base: var(--ion-color-chip);
  --ion-color-base-rgb: var(--ion-color-chip-rgb);
  --ion-color-contrast: var(--ion-color-chip-contrast);
  --ion-color-contrast-rgb: var(--ion-color-chip-contrast-rgb);
  --ion-color-shade: var(--ion-color-chip-shade);
  --ion-color-tint: var(--ion-color-chip-tint);
}

.ion-color-light-green {
	--ion-color-base: var(--ion-color-light-green);
	--ion-color-base-rgb: var(--ion-color-light-green-rgb);
	--ion-color-contrast: var(--ion-color-light-green-contrast);
	--ion-color-contrast-rgb: var(--ion-color-light-green-contrast-rgb);
	--ion-color-shade: var(--ion-color-light-green-shade);
	--ion-color-tint: var(--ion-color-light-green-tint);
}