$icon-font-path: '../node_modules/bootstrap-sass/assets/fonts/bootstrap/'

$font-size-1 : 1.6em // ~25px
$font-size-2 : 1.25em // ~17px
$font-size-3 : 1em // ~16px
$font-size-4 : 0.9em // ~14px
$font-size-5: 0.8em // ~12px

$small: 480px
$medium: 768px
$large: 1024px
$x-large: 1280px

// Width of sidebar menu when not collapsed
$uncollapsed-menu-width: 270px
// Width of sidebar menu when collapsed
$collapsed-menu-width: 55px

// Pictures sizes
$small-picture: 45px
$medium-picture: 60px
$big-picture: 80px

// Login form sizes
$login-form-input-min-width: 200px
$login-form-input-max-width: 500px
$login-form-input-horizontal-padding: 100px
$login-form-input-vertical-padding: 25px

// Z-index ordering
$z-index-onboarding-parallax: -1
$z-index-modal-backdrop: 0
$z-index-spinner-message: 0
$z-index-popup-close-button: 1
$z-index-welcome-header : 2
$z-index-mobile-button: 5
$z-index-datatable-body : 9
$z-index-button: 10
$z-index-item-divider: 10
$z-index-fab-button: 10
$z-index-preview-secondary-header: 11
$z-index-header: 11
$z-index-ios-header: 200
$z-index-popup-background: 500
$z-index-debugging-tool-card: 10000
//ion-modal used for our mobile menus have z-index : 20004
$z-index-modal-popup : 20005
$z-index-spinner: 30000

// Input variables
$input-height : 36px
