.wi-time-1:before {content: $time-1;}
.wi-time-2:before {content: $time-2;}
.wi-time-3:before {content: $time-3;}
.wi-time-4:before {content: $time-4;}
.wi-time-5:before {content: $time-5;}
.wi-time-6:before {content: $time-6;}
.wi-time-7:before {content: $time-7;}
.wi-time-8:before {content: $time-8;}
.wi-time-9:before {content: $time-9;}
.wi-time-10:before {content: $time-10;}
.wi-time-11:before {content: $time-11;}
.wi-time-12:before {content: $time-12;}