$red : #dd1848
$blue : #003ef6
$purple: #4c27ba
$darkpurple: rgba($purple, 0.1)
$green : #50e3c2
$darkBlue : #36344b
$teal : #9290a7
$lightTeal: #C5C5C5
$orange: #daa636
$lightOrange: #f5a623
$darkOrange: #EA940B
$brightOrange: #F54900
$brightOrangeLight: #F5490010
$black: #000
$white: #fff
$bermudaGreen: #80cbc4
$lightGreen: #14A735
$forestGreen: #529c6b
$darkGreen: #056611
$solitude: #EBEDEF
$pureOrange: #E58600
$lightOrange: #ffd7b5
$paleOrange: #FFF7EB
$darkRed: #F5490033
$lightRed: #ff000030
$hightlightBlue: #5263D5
$lightGreyWhite: #CCCCCC
$dark: #232132
$placeHolderGrey: $teal
$mediumGrey: #989AA2
$grey: #CCC
$labelGrey: #666666
$darkWhite : #E9E9E9
$lightGrey: #f4f4f4
$lighterGrey: #fcfcfc
$borderGray: #00000040
$frenchGrey: #C7C7CC
$darkGrey: #d3d3d3
$darkerGrey: #00000080
$subTextGrey: #7F7F7F
$lightBlack: #00000095
$borderGrey: #dedede
$borderGreySecondary: #DEDDE4
$borderGreyishBlue: #C5C5C7
$whitesmoke : #f5f5f5
$lightWhiteSmoke: #DDDDDD26
$greybley: #dbe0e3
$warning: $orange
$error: $red
$info: $blue
$success: $forestGreen
$text-black-color: $darkBlue
$text-grey-color: $teal
$trolleyGrey: #808080
$darkgrey: #a9a9a9
$greyblue: #e9ecef
$purpleBlue: #795DCB
$vertical-bar-grey: #00000026
$bright-purple: #7857DB
$light-purple: #E1DBF3
$pale-purple: #f7f7fc
$errorCardBackground: #DD2948
$approve-color: #008000

$border-grey:  #ced4da
$border-lightGrey: #DDDFE3
$border-lighterGrey: #9290a74d

$field-border-color: $darkWhite

$deactivated-text-color: #21252980
$checked-tab-background: #F549001A

$separator-color: #d9d9d980
$modal-subtext-color: #737373
$disabled-button-grey: #00000080
$user-details-table-border: #00000040
$user-details-table-background: #D9D9D91A
$user-details-input-border: #000000BF
$user-details-input-disabled-background: #D3D3D350

$blue-transparent: rgba( $grey, .2 )

$highlight-purple: rgba( $purple, .05 )
$highlight-green: rgba( $lightGreen, .05 )
$highlight-darker-purple: rgba( $purple, 0.07)
$backdrop-color: rgba( $darkBlue, .1)
$backdrop-darker-color: rgba( $darkBlue, .5)
$warning-orange: rgba(245, 73, 0, 1)

$light-shadow-color: rgba( $purple, .1 )

/* task status colors */
$task-in-progress-color: $lightOrange
$task-not-started-color: $blue
$task-completed-color: $green
$task-suspended-color: $teal
$task-overdue-color: $red
$task-delayed-color: #FF5F84
$task-planned-ready-to-start-color: #87A4FF
$task-all-color: $purple
$task-mytasks-color: $purple

/* pills colors */
$pill : #c5b9e9
$pill-text : #232132


/* buttons colors */
$button-delete: $red
$button-submit: $purple
$button-draft: $lightOrange
$button-approve: $forestGreen
$button-reject: $teal
$button-cancel: $purple
$button-save: $purple
$button-options: $purple
$button-add: $purple
$button-back: $teal

/* event status colors */
$event-draft: $lightOrange
$event-submitted: $purple
$event-approved: $green
$event-rejected: $teal
